import React, { Fragment, useState } from 'react';

import Col from 'react-bootstrap/Col';

import { Payment } from './Payment';
import { CheckoutBottom } from './CheckoutBottom';

export default function Checkout({ event, addOns, setStatus, intentId, cart }) {
    let order = sessionStorage.getItem('order');
    if (order) order = JSON.parse(order);

    const paymentDeclined = (err) => {
        console.log("implement using modalsProvider")
    };

    const checkValid = (e) => {
        let paymentButton = document.getElementById('payment-btn');
        let paymentButtonDisabled = document.getElementById('payment-btn-disabled');

        if (e && e.complete) {
            paymentButton.classList.add('d-flex');
            paymentButton.classList.remove('d-none');
            paymentButtonDisabled.classList.add('d-none');
        }
        else {
            paymentButton.classList.add('d-none');
            paymentButtonDisabled.classList.remove('d-none');
            paymentButtonDisabled.classList.add('d-block');
        }
    };

    return (
        <Fragment>
            <Col>
                <section>
                    <Payment checkValid={checkValid} />
                </section>
                <CheckoutBottom
                    setStatus={setStatus}
                    intentId={intentId}
                    paymentDeclined={paymentDeclined}
                    cart={cart}
                />
            </Col>
        </Fragment>
    )
}