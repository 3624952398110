import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { TicketErrorModal } from "./TicketErrorModal";
import { useNavigate } from "react-router-dom";
import { placeTicketsIntoCart } from "../../../../../utilities/api";
import { useTickets } from "../TicketsProvider/TicketsProvider";

// Create the context
const CheckoutContext = createContext(undefined);

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error("Context must be used within a CheckoutProvider");
  }
  return context;
};

// Provide all functionality + modal for proceeding to checkout
export const CheckoutProvider = ({ children }) => {
  const dialogRef = useRef(null);
  const navigate = useNavigate();
  const {
    event,
    code,
    selectedFromGA,
    selectedFromSeats,
    selectedFromQuickpicks,
  } = useTickets();

  const [seatedTickets, setSeatedTickets] = useState();
  const [seatedError, setSeatedError] = useState(null);
  const [checkingAvailability, setCheckingAvailability] = useState(false);

  useEffect(() => {
    if (seatedTickets && seatedTickets.success) {
      navigate(`/checkout?eventId=${event.uuid}`);
    }
  }, [seatedTickets]);

  useEffect(() => {
    if (seatedError) {
      dialogRef.current.showModal();
    }
  }, [seatedError]);

  // Utility function to normalize GA items
  const normalizeGAItems = (items, quickpick) => {
    console.log("normalizeGaItems: ", items);
    if (!quickpick) {
      return items.map((item) => ({
        offer: item?.offer,
        listing: item?.listing,
        inventoryDBId: item.inventoryDBId,
        quantity: item.quantity,
        sectionDBId: null,
      }));
    }

    console.log("normalizeGAItems isQuickpick")
    return [
      {
        offer: items.pick?.offers && items.pick?.offers[0],
        listing: items.pick?.listing,
        inventoryDBId: items.pick.inventoryDBId,
        quantity: items.quantity,
        sectionDBId: items.pick.sectionDBId,
      },
    ];
  };

  // Utility function to normalize Seat items
  const normalizeSeatItems = (items, quickpick) => {
    console.log("normalizeSeatItems: ", items);
    if (!quickpick) {
      return items.map((item) => ({
        offer: item.seatInfo?.offer,
        listing: item.seatInfo?.listing,
        resale: item.seatInfo.resale,
        rowDBId: item.seatInfo.rowDBId,
        sectionDBId: null,
        seatId: item.seatId,
        quantity: 1,
      }));
    }

    console.log("normalizeSeatItems isQuickpick")
    return [
      {
        offer: items.pick?.offers && items.pick.offers[0],
        listing: items.pick.listing,
        rowDBId: items.pick.rowDBId,
        sectionDBId: items.pick.sectionDBId,
        seatId: null,
        quantity: items.quantity,
      },
    ];
  };

  // Function to normalize all reservation data
  const normalizeReservationData = (data) => {
    const {
      isQuickpick,
      selectedFromQuickpicks,
      selectedFromGA,
      selectedFromSeats,
      event,
    } = data;

    let normalizedData = {
      event,
      gaItems: [],
      seatItems: [],
    };

    if (isQuickpick) {
      const isGA = selectedFromQuickpicks.pick.type === "GA";
      if (isGA) {
        normalizedData.gaItems = normalizeGAItems(selectedFromQuickpicks, true);
      } else {
        normalizedData.seatItems = normalizeSeatItems(selectedFromQuickpicks, true);
      }
    } else {
      if (selectedFromGA && selectedFromGA.length > 0) {
        normalizedData.gaItems = normalizeGAItems(selectedFromGA, false);
      }
      if (selectedFromSeats && selectedFromSeats.length > 0) {
        normalizedData.seatItems = normalizeSeatItems(selectedFromSeats, false);
      }
    }

    return normalizedData;
  };

  // This is only valid for a single ticket
  const checkout = (isQuickpick) => {
    console.log("----");
    console.log("----");
    console.log("CHECKOUT");
    console.log("selectedFromGA", selectedFromGA);
    console.log("selectedFromSeats", selectedFromSeats);
    console.log("selectedFromQuickpicks", selectedFromQuickpicks);
    console.log("----");
    console.log("----");

    setCheckingAvailability(true);
    let data = {
      isQuickpick: isQuickpick,
      selectedFromQuickpicks: selectedFromQuickpicks,
      selectedFromGA: selectedFromGA,
      selectedFromSeats: selectedFromSeats,
      event: event,
    };


    // Both Quickpicks and Map selections are the same things
    // just in a different formats, so we make them uniform
    // -> making the api simpler
    const normalizedData = normalizeReservationData(data);

    placeTicketsIntoCart(normalizedData)
      .then((res) => {
        console.log("placeTicketsIntoCart res: ", res.data);
        sessionStorage.setItem("cart", JSON.stringify({ eventUUID: res.data.event.uuid }))
        setSeatedTickets(res.data);
      })
      .catch((err) => {
        console.error("placeTicketsIntoCart err: ", err);
        console.log("placeTicketsIntoCart err: ", err)
        if (err.response) {
          setSeatedError({
            status: err.response.status,
            title: "Selected tickets not available",
            message: err.response.data.error.message || "An error occurred",
            buttonText: "Return to tickets list"
          });
        } else {
          setSeatedError({
            status: 500,
            title: "Selected tickets not available",
            message: "Network error occurred",
            buttonText: "Return to tickets list"
          });
        }
      })
      .finally(() => {
        setCheckingAvailability(false);
      });
  };

  const resetSeatedError = () => {
    setSeatedError(null);
  };

  const modalClose = () => {
    resetSeatedError();
    dialogRef.current.close();
    // Probably just want to refresh here if tickets not available
  };

  const openTicketErrorModal = (title, message) => {
    setSeatedError({
      status: 500,
      title: title,
      message: message,
      buttonText: "Got it"
    });
  }

  return (
    <CheckoutContext.Provider
      value={{
        checkout,
        checkingAvailability,
        event,
        openTicketErrorModal
      }}
    >
      {children}
      <TicketErrorModal dialogRef={dialogRef} seatedError={seatedError} handleClose={modalClose} />
    </CheckoutContext.Provider>
  );
};
