import { Elements } from "@stripe/react-stripe-js";

import Card from "react-bootstrap/Card";

import { Checkout } from "./Checkout";

import "./paymentMethod.scss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PaymentMethod({
  clientSecret,
  stripePromise,
  options,
  status,
  event,
  addOns,
  setStatus,
  intentId,
  cart,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (status === "successful") {
      navigate("checkout-success");
    }
  }, [status]);
  
  return (
    <Card className='card-xl card-xl--light checkout-payment'>
      <Card.Body className='w-100'>
        <div className='card-form'>
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <Checkout
                event={event}
                addOns={addOns}
                setStatus={setStatus}
                intentId={intentId}
                cart={cart}
              />
            </Elements>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
