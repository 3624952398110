import React, {
  createContext,
  Suspense,
  useContext,
  useRef,
  lazy,
  useState,
} from "react";
import { CenterBottomModal } from "../../../components/CenterBottomModal";
const LazyUnlockOffers = lazy(() => import("./UnlockOffers/UnlockOffers"));

// Create the context
const UnlockContext = createContext(undefined);

export const useUnlock = () => {
  const context = useContext(UnlockContext);
  if (!context) {
    throw new Error("Context must be used within a UnlockProvider");
  }
  return context;
};

// Provide certain type of modal used in the page
export const UnlockProvider = ({ children }) => {
  const [offersToUnlock, setOffersToUnlock] = useState([]);
  const dialogRef = useRef(null);

  const openUnlockModal = (offers) => {
    setOffersToUnlock(offers);
    dialogRef.current.showModal();
  };

  const closeUnlockModal = () => {
    dialogRef.current.close();
  };

  return (
    <UnlockContext.Provider
      value={{
        openUnlockModal,
        closeUnlockModal,
      }}
    >
      {children}
      <CenterBottomModal
        dialogRef={dialogRef}
        handleClose={closeUnlockModal}
        title={"Unlock offers"}
        blurred={true}
      >
        <Suspense fallback={null}>
          <LazyUnlockOffers offers={offersToUnlock} />
        </Suspense>
      </CenterBottomModal>
    </UnlockContext.Provider>
  );
};
