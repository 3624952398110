import React from "react";

import {
  formatDateTime,
  formatShortAddress,
  getTimezoneDate,
  timezones,
} from "../../../../utilities/helpers";

import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

import placeholder from "../../../../assets/placeholder.png";

import "./event.scss";

export default function Event({ event, onClick, showBack, openModal }) {
  let timezone = timezones(event?.venue?.timezone);

  return (
    <header className='purchaseFlow-event'>
      <Card body className='event-container card-xl card-xl--dark'>
        {/* THIS gap should be 14 but now its 16  */}
        <Stack direction='horizontal' className='align-items-center gap-3'>
          {showBack && (
            <Button
              variant='default'
              size='sm'
              className='btn--icon flex-shrink-0 mobile-only border-0 w-auto'
              onClick={onClick}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                role='img'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M15.7071 19.2071C16.0976 18.8166 16.0976 18.1834 15.7071 17.7929L9.91421 12L15.7071 6.20711C16.0976 5.81658 16.0976 5.18342 15.7071 4.79289C15.3166 4.40237 14.6834 4.40237 14.2929 4.79289L8.5 10.5858C7.71895 11.3668 7.71895 12.6332 8.5 13.4142L14.2929 19.2071C14.6834 19.5976 15.3166 19.5976 15.7071 19.2071Z'
                  fill='#777E91'
                />
              </svg>
            </Button>
          )}
          <div className='tablet-desktop-only'>
            <img
              src={event?.image?.url || placeholder}
              alt={`Cover art for ${event?.name} event`}
              width='88'
              height='88'
              className='event-image'
            />
          </div>
          <div className='details'>
            <div className='d-flex flex-column'>
              <Card.Title as='h5' className='card-title-sm'>
                {event?.name}
              </Card.Title>
              <Stack direction='horizontal'>
                <p>
                  <span className='spant'>
                    {formatDateTime(
                      getTimezoneDate(event?.start, timezone),
                      "dateOnly"
                    )}
                    ,
                  </span>
                  {event?.display_start_time && (
                    <>
                      <span className='spant px-1'>
                        {formatDateTime(
                          getTimezoneDate(event?.start, timezone),
                          "timeOnly"
                        )}
                      </span>
                      <span className='tablet-desktop-only spant'>at</span>
                    </>
                  )}
                  <span className='tablet-desktop-only venue-info'>
                    &nbsp;{event?.venue?.name},{" "}
                    <span>{formatShortAddress(event?.venue)}</span>
                  </span>
                </p>
              </Stack>
            </div>
            <Button
              onClick={() => openModal("Event Information")}
              variant='link'
              className='infob'
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.99935 16.6665C13.6812 16.6665 16.666 13.6817 16.666 9.99984C16.666 6.31794 13.6812 3.33317 9.99935 3.33317C6.31745 3.33317 3.33268 6.31794 3.33268 9.99984C3.33268 13.6817 6.31745 16.6665 9.99935 16.6665ZM9.99935 18.3332C14.6017 18.3332 18.3327 14.6022 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332Z'
                  fill='#777E91'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.99935 5.8335C9.53911 5.8335 9.16602 6.20659 9.16602 6.66683C9.16602 7.12707 9.53911 7.50016 9.99935 7.50016C10.4596 7.50016 10.8327 7.12707 10.8327 6.66683C10.8327 6.20659 10.4596 5.8335 9.99935 5.8335ZM9.99935 9.16683C9.53911 9.16683 9.16602 9.53993 9.16602 10.0002V13.3335C9.16602 13.7937 9.53911 14.1668 9.99935 14.1668C10.4596 14.1668 10.8327 13.7937 10.8327 13.3335V10.0002C10.8327 9.53993 10.4596 9.16683 9.99935 9.16683Z'
                  fill='#777E91'
                />
              </svg>
              <span className='tablet-desktop-only'>Event information</span>
            </Button>
          </div>
        </Stack>
      </Card>
    </header>
  );
}
