import { ModalsProvider } from "./providers/ModalsProvider/ModalsProvider";
import { SeatmapProvider } from "./providers/SeatmapProvider";
import { SecondModalProvider } from "./providers/SecondModalProvider";
import { CheckoutProvider } from "./providers/CheckoutProvider";
import { TicketsProvider } from "./providers/TicketsProvider/TicketsProvider";
import NewSeatedPurchaseContainer from "./NewSeatedPurchaseContainer/NewSeatedPurchaseContainer";
import { UnlockProvider } from "./providers/UnlockProvider/UnlockProvider";

export function NewSeatedPurchaseWrapper({
  event,
  code,
  offers,
  tickets,
  listings,
  isScheduled,
  scheduledStartDate,
  priceRangeMinMax,
  eventTicketLimit,
}) {
  return (
    <TicketsProvider
      offers={offers}
      tickets={tickets}
      listings={listings}
      event={event}
      code={code}
      priceRangeMinMax={priceRangeMinMax}
      eventTicketLimit={eventTicketLimit}
    >
      <CheckoutProvider>
        <UnlockProvider>
          <SecondModalProvider>
            <ModalsProvider>
              <SeatmapProvider>
                <NewSeatedPurchaseContainer
                  isScheduled={isScheduled}
                  scheduledStartDate={scheduledStartDate}
                />
              </SeatmapProvider>
            </ModalsProvider>
          </SecondModalProvider>
        </UnlockProvider>
      </CheckoutProvider>
    </TicketsProvider>
  );
}
