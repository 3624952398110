import { useEffect, useState } from "react";
import "./checkoutSuccessWrapper.scss";
import { AdditionalInfo } from "../components/AdditionalInfo";
import { findMadeOrder, getCart } from "../../../utilities/api";
import { BuyingThis } from "../components/BuyingThis";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PageLoadingContainer } from "../../PageLoadingContainer";

export default function CheckoutSuccessWrapper() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // Hide intercom widget on this page
  // This does hide it until you refresh the page / turn it back on again
  const [order, setOrder] = useState(null);
  useEffect(() => {
    console.log("CheckoutSuccessWrapper");
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  useEffect(() => {
    let order = sessionStorage.getItem("order");
    if (order) order = JSON.parse(order);

    let data = {
      id: order?.id,
    };

    console.log("order: ", order);
    findMadeOrder(data).then((res) => {
      console.log("findMadeOrder: ", res.data);
      setOrder(res.data);
      setLoading(false);
    });
  }, []);

  const goToMyWallet = () => {
    navigate("/my-events");
  };

  return (
    <>
      {loading ? (
        <PageLoadingContainer
          text='Success!'
          subtext='You will be redirected to success page.'
          showLoader={false}
          icon='ticket'
        />
      ) : (
        <div className='checkout-success-wrapper purchaseflow-gradient'>
          <div className='success-grid card-xl-no-mobile card-xl-no-mobile--dark'>
            <div className='grid-item success-header'>
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M16.0013 29.3333C23.3651 29.3333 29.3346 23.3638 29.3346 16C29.3346 8.63616 23.3651 2.66663 16.0013 2.66663C8.63751 2.66663 2.66797 8.63616 2.66797 16C2.66797 23.3638 8.63751 29.3333 16.0013 29.3333ZM23.3751 12.7071C23.7656 12.3165 23.7656 11.6834 23.3751 11.2929C22.9846 10.9023 22.3514 10.9023 21.9609 11.2929L14.668 18.5857L11.3751 15.2929C10.9846 14.9023 10.3514 14.9023 9.96086 15.2929C9.57034 15.6834 9.57034 16.3165 9.96086 16.7071L13.9609 20.7071C14.1484 20.8946 14.4028 21 14.668 21C14.9332 21 15.1875 20.8946 15.3751 20.7071L23.3751 12.7071Z'
                  fill='#45B36B'
                />
              </svg>
              <h2>Payment Successful</h2>
            </div>

            <div className='grid-item'>
              <BuyingThis cart={order} />
              <div className="csw-bottom">
                <Button
                  variant='primary'
                  size='lg'
                  onClick={goToMyWallet}
                  className=" mt-0"
                >
                  Go to my wallet
                </Button>
              </div>
            </div>

            <div className='grid-item additional-info'>
              <AdditionalInfo
                bg='light'
                MTDescription={
                  "Your phone is your ticket. You will instantly receive your ticket and store it in your account."
                }
                BPDescription={
                  "Blocktickets offers bot and resale protection. Your spot is safe with us."
                }
              />
            </div>

            <div className='grid-item order-summary card-xl card-xl--light'>
              <h3>Order Summary</h3>
              <div className='summary-item'>
                <span className='si-t'>Payment method</span>
                <span className='si-v'>****4588</span>
              </div>
              <div className='summary-item'>
                <span className='si-t'>Subtotal</span>
                <span className='si-v'>$270.00</span>
              </div>
              <div className='summary-item'>
                <span className='si-t'>Tax</span>
                <span className='si-v'>$9.00</span>
              </div>
              <div className='summary-item total'>
                <span className='si-t'>Total</span>
                <span className='si-v'>$279.00</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
