import { ModalTop } from "../../../../components/ModalTop";
import "./seatmapModal.scss";
import { Suspense, lazy } from "react";
const LazySeatmapWrapper = lazy(() =>
  import("../SeatmapWrapper/SeatmapWrapper")
);

export default function SeatmapModal({
  dialogRef,
  hideSeatmap,
  title,
  data,
  setData,
  dialogOpen,
  background,
}) {
  const handleClick = (event) => {
    if (event.target === dialogRef.current) {
      hideSeatmap();
    }
  };

  return (
    <dialog ref={dialogRef} onClick={handleClick} className={`dialog-sm-base`}>
      <div className='dialog-sm-inner'>
        <ModalTop
          title={title}
          handleClose={hideSeatmap}
          isSeatmapModal={true}
        />
        <div className='sm-content'>
          <Suspense fallback={null}>
            {dialogOpen && (
              <LazySeatmapWrapper
                data={data}
                setData={setData}
                handleClose={hideSeatmap}
                background={background}
              />
            )}
          </Suspense>
        </div>
      </div>
    </dialog>
  );
}
