import Stack from "react-bootstrap/Stack";
import { PurchaseOption } from "./PurchaseOption";
import { Countdown } from "../../../../components/Countdown";
import { Spinner } from "../../../../../LoadingContainer/Spinner";
import { GetSoldout } from "../../../../components/GetStatus/GetSoldout";
import { useTickets } from "../../../providers/TicketsProvider/TicketsProvider";
import { useModals } from "../../../providers/ModalsProvider/ModalsProvider";

export default function ListingsContainer({ isScheduled, scheduledStartDate }) {
  const { openCBModal, setViewImage } = useModals();
  const { isLoadingTickets, quickpicks, soldout } = useTickets();

  return (
    <>
      {isLoadingTickets ? (
        <Stack className='align-items-center'>
          <Spinner variant='primary' size='lg' />
        </Stack>
      ) : (
        <>
          {quickpicks && quickpicks.length > 0 ? (
            <div className='flex-one pxb' style={{ overflowY: "auto", paddingBottom: '16px'}}>
                <Stack as='ul' gap={2} className='tickets-col'>
                  {quickpicks.map((pick, i) => {
                    return (
                      <PurchaseOption
                        key={i}
                        id={i.toString()}
                        pick={pick}
                        openCBModal={openCBModal}
                        setViewImage={setViewImage}
                      />
                    );
                  })}
                </Stack>
            </div>
          ) : (
            <>
              {isScheduled ? (
                <Countdown scheduledStartDate={scheduledStartDate} />
              ) : soldout ? (
                <GetSoldout icon={true} fixedMobile={false} />
              ) : (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{ backgroundColor: 'gray', color: 'white', fontWeight: 'bold', padding: '16px'}}>No tickets found with your selection</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
