import { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  fullHeightContainer,
  removeFullHeightContainer,
} from "../../../../utilities/helpers";

import { Event } from "../../components/Event";
import { TicketSelectionPanel } from "./TicketSelectionPanel";
import { useModals } from "../providers/ModalsProvider/ModalsProvider";

import { useTickets } from "../providers/TicketsProvider/TicketsProvider";
import "../../purchaseFlow.scss";
import "./newSeatedPurchaseContainer.scss";

export default function NewSeatedPurchaseContainer({
  isScheduled,
  scheduledStartDate,
}) {
  const navigate = useNavigate();
  const { event } = useTickets();
  const { openCBModal } = useModals();

  // I dont understand this
  // What does this do?
  useLayoutEffect(() => {
    const el = document.querySelector("#main-container");
    fullHeightContainer(el);

    return () => {
      removeFullHeightContainer(el);
    };
  }, []);

  // Hide intercom widget on this page
  // This does hide it until you refresh the page / turn it back on again
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={` max-h-screen purchaseflow-gradient`}
    >
      <div className='d-flex-column nstc-ss'>
        <div className="pxccc">
          <Event
            event={event}
            onClick={handleGoBack}
            openModal={openCBModal}
            showBack={true}
          />
        </div>
        <TicketSelectionPanel
          isScheduled={isScheduled}
          scheduledStartDate={scheduledStartDate}
        />
      </div>
    </div>
  );
}
