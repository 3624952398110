import { formatCurrency } from "../../../../../../../utilities/helpers";

import Stack from "react-bootstrap/Stack";
import Badge from "react-bootstrap/Badge";

import placeholder from "../../../../../../../assets/placeholder.png";

import { useSecondModal } from "../../../../providers/SecondModalProvider/SecondModalProvider";
import "./purchaseOption.scss";
import { useTickets } from "../../../../providers/TicketsProvider/TicketsProvider";

// Yeah need2find a way to combine Ticket & BuyingThis
export default function PurchaseOption({ pick, openCBModal, setViewImage }) {
  const isListing = pick.listing;

  let isGeneralAdmission;
  let ticketPrice;
  let totalTicketPrice;
  let offerName;
  let sectionId;
  let rowId;
  let availableCount;
  let inventoryType;

  const { selectQuickPick, eventTicketLimit } = useTickets();
  const { openModal } = useSecondModal();

  let getTicketImage = () => {
    let link =
      false == "Theater"
        ? `https://blocktickets-development.nyc3.digitaloceanspaces.com/thalia/$ticket.screenshot`
        : placeholder;
    return link;
  };

  const getActiveDiscountName = (offer) => {
    if (!offer.offer_discounts || offer.offer_discounts.length === 0) {
      return null;
    }

    const now = new Date();
    const timeLimitedDiscounts = offer.offer_discounts.filter(
      (discount) =>
        discount.type === "time-limited" && new Date(discount.expires) > now
    );

    if (timeLimitedDiscounts.length === 0) {
      return null;
    }

    // Sort discounts by start date (assuming there's a 'starts' field; if not, we'll need to adjust this)
    timeLimitedDiscounts.sort(
      (a, b) => new Date(a.starts) - new Date(b.starts)
    );

    return timeLimitedDiscounts[0].name;
  };

  if (isListing) {
    offerName = "Verified Resale";
    inventoryType = "open";
  } else {
    const offer = pick.offers[0];
    offerName = getActiveDiscountName(offer) || offer.name;
    inventoryType = offer.inventoryType;
  }

  isGeneralAdmission = pick?.type === "GA";
  sectionId = pick?.sectionId;
  rowId = pick?.rowId;
  availableCount = pick.availableCount;
  ticketPrice = pick.price;
  totalTicketPrice = ticketPrice;

  const showTicketView = (e) => {
    e.stopPropagation();

    // The following controls modal title AND rendering of modal content
    setViewImage(getTicketImage());
    openCBModal("Your view");
  };

  const clickTicketGroup = () => {
    selectQuickPick(pick);
    // do something more clever in the future
    openModal();
  };

  return (
    <li className={`select_ticket`}>
      <button
        onClick={clickTicketGroup}
        role='button'
        className='ticket-inner mt-0'
      >
        <div onClick={showTicketView} className='img-wrap'>
          <img
            className='ticket-view'
            src={getTicketImage()}
            alt='Seat view for this ticket'
          />
        </div>
        <div className='ticket-info'>
          <div className='ticket-info-c1'>
            <Stack className='gap-md-2 flex-grow-1 align-items-start'>
              <QuickpickBadge
                isListing={isListing}
                inventoryType={inventoryType}
                offerName={offerName}
              />
              <h3 className={`ticket-seat seat`}>
                {isGeneralAdmission
                  ? `General Admission ${sectionId}`
                  : `Sec ${sectionId} \u2022 Row ${rowId}`}
              </h3>
            </Stack>
            {/* {minQuantity} - {maxQuantity} */}
            <POTicketQuantity
              eventTicketLimit={eventTicketLimit}
              availableCount={availableCount}
              offers={pick?.offers}
              listing={pick?.listing}
            />
          </div>
          <p className='ticket-info-c2'>
            <span className='ticket-price'>
              {formatCurrency(totalTicketPrice)} each
            </span>
            <span className='d-block ticket-fees'>
              Incl. Taxes<span className=' tablet-desktop-only'> & Fees</span>
            </span>
          </p>
        </div>
      </button>
    </li>
  );
}

function POTicketQuantity({
  eventTicketLimit,
  availableCount,
  offers,
  listing,
}) {
  const getTicketText = () => {
    if (listing) {
      return `${listing.quantity} - ${listing.quantity} Tickets`;
    }

    let offer = offers[0];
    if (!offer) {
      return `1 - ${availableCount} Tickets`;
    }

    const max =
      eventTicketLimit !== null
        ? Math.min(eventTicketLimit, availableCount)
        : availableCount;

    if (offer.limit) {
      return `${offer.limit} - ${offer.limit} Tickets`;
    } else if (offer.maxQuantity) {
      const upperLimit = Math.min(offer.maxQuantity, max);
      return `1 - ${upperLimit} Tickets`;
    } else if (offer.minQuantity) {
      return `${offer.minQuantity} - ${max} Tickets`;
    } else if (offer.multipleOf) {
      return `Multiple of ${offer.multipleOf} up to ${max} Tickets`;
    } else {
      return `1 - ${max} Tickets`;
    }
  };

  const ticketText = getTicketText();

  return <p className='ticket-quantity'>{ticketText}</p>;
}

export function QuickpickBadge({ isListing, inventoryType, offerName }) {
  let badgeText = offerName;
  let badgeClass = "badge-exclusive d-flex flex-row align-items-center gap-1";
  let BadgeIcon = null;

  if (isListing) {
    badgeText = "Verified Resale";
    badgeClass = "badge-resale d-flex flex-row align-items-center gap-1";
    BadgeIcon = ResaleSvg;
  } else if (inventoryType === "open") {
    badgeClass = "badge-primary  d-flex flex-row align-items-center gap-1";
  } else {
    BadgeIcon = ExclusiveSvg;
  }

  return (
    <Badge bg='default' className={badgeClass}>
      {BadgeIcon && <BadgeIcon />}
      {badgeText}
    </Badge>
  );
}

function ExclusiveSvg() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.3879 2.08514C7.6195 1.54844 8.38051 1.54844 8.61211 2.08514L10.0655 5.45307C10.1635 5.68032 10.3794 5.83454 10.6262 5.85361L14.3533 6.14161C14.9513 6.18781 15.1889 6.93852 14.7264 7.32038L11.9235 9.63456C11.7258 9.79779 11.6392 10.0599 11.7008 10.3088L12.5634 13.7944C12.7053 14.3676 12.0869 14.8286 11.578 14.529L8.33822 12.6217C8.12949 12.4988 7.87053 12.4988 7.66179 12.6217L4.42197 14.529C3.9131 14.8286 3.29475 14.3676 3.43661 13.7944L4.29924 10.3088C4.36083 10.0599 4.27425 9.79779 4.07654 9.63456L1.27362 7.32038C0.811112 6.93852 1.04872 6.18781 1.64671 6.14161L5.37381 5.85361C5.62058 5.83454 5.83648 5.68032 5.93455 5.45307L7.3879 2.08514Z'
        fill='#9757D7'
      />
    </svg>
  );
}

function ResaleSvg() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.66665 8.00016C2.66665 5.05464 5.05446 2.66683 7.99998 2.66683C9.67801 2.66683 11.1525 3.44249 12.1215 4.6623L11.0019 4.65902C10.6337 4.65794 10.3344 4.95554 10.3333 5.32373C10.3322 5.69192 10.6298 5.99127 10.998 5.99235L13.2933 5.99907C13.3189 6.00064 13.3446 6.00073 13.3705 5.9993L13.6647 6.00016C13.8418 6.00068 14.0119 5.93067 14.1374 5.80559C14.2628 5.68051 14.3333 5.51065 14.3333 5.3335L14.3333 2.66683C14.3333 2.29864 14.0348 2.00016 13.6666 2.00016C13.2985 2.00016 13 2.29864 13 2.66683L13 3.63314C11.7916 2.22708 10.0153 1.3335 7.99998 1.3335C4.31808 1.3335 1.33331 4.31826 1.33331 8.00016C1.33331 8.36835 1.63179 8.66683 1.99998 8.66683C2.36817 8.66683 2.66665 8.36835 2.66665 8.00016Z'
        fill='#F0641E'
      />
      <path
        d='M13.3334 8.00016C13.3334 10.9457 10.9455 13.3335 8.00002 13.3335C6.32199 13.3335 4.84748 12.5578 3.87852 11.338L4.99807 11.3413C5.36626 11.3424 5.66561 11.0448 5.66668 10.6766C5.66776 10.3084 5.37016 10.0091 5.00197 10.008L2.70673 10.0013C2.68113 9.99969 2.65535 9.9996 2.62954 10.001L2.33531 10.0002C2.15816 9.99965 1.98809 10.0697 1.86264 10.1947C1.73719 10.3198 1.66669 10.4897 1.66669 10.6668L1.66669 13.3335C1.66669 13.7017 1.96517 14.0002 2.33336 14.0002C2.70155 14.0002 3.00002 13.7017 3.00002 13.3335L3.00002 12.3672C4.20839 13.7732 5.9847 14.6668 8.00002 14.6668C11.6819 14.6668 14.6667 11.6821 14.6667 8.00016C14.6667 7.63197 14.3682 7.3335 14 7.3335C13.6318 7.3335 13.3334 7.63197 13.3334 8.00016Z'
        fill='#F0641E'
      />
    </svg>
  );
}
