import Button from "react-bootstrap/Button";
import "./addDiscounts.scss"
import { useSpecialDiscounts } from "../../providers/SpecialDiscountProvider";

export default function AddDiscounts(cart) {
    const { openModal } = useSpecialDiscounts();
    console.log("addDiscounts: ", cart.cart)
  return (
    <div className="vjx_add_discount">
        <div className="vjx_discount_inner">
            <h4>Special discount</h4>
            <h6>Applicable to Veterans,  children under 18 or special category</h6>
        </div>
        <Button type="primary" size="lg" onClick={() => openModal(cart?.cart?.tickets)}>Add discount</Button>
    </div>
  );
}
