import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import Form from 'react-bootstrap/Form';
import './payment.scss';

export default function Payment({ checkValid }) {
    const CheckoutForm = () => {
        return (
            <Form id="checkout-form">
                <PaymentElement onChange={(e) => checkValid(e)} wallets={true} />
            </Form>
        );
    };

    // Boostrap asks for a div with collapse/accordion
    return (
        <div>
            <CheckoutForm />
        </div>
    );
}
