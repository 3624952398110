import React from "react";

import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Dropdown from "react-bootstrap/Dropdown";

import "./quantityFilter.scss";
import { useTickets } from "../../NewSeatedPurchaseWrapper/providers/TicketsProvider/TicketsProvider";

export default function QuantityFilter({
  btnStyle,
  sm,
  style,
  range,
  handleChange,
  dropdown,
}) {
  const { quantity } = useTickets();
  const numbers = Array.from(
    { length: range.max - range.min + 1 },
    (_, i) => i + range.min
  );

  if (dropdown) {
    return (
      <ul className={`mb-1 p-1 actions-bar ${style || ""}`}>
        {numbers.map((number) => (
          <Dropdown.Item
            eventKey={number.toString()}
            active={number === quantity}
            key={number}
            className={``}
            variant='default'
            value={number}
          >
            {number} Ticket{number > 1 && "s"}
          </Dropdown.Item>
        ))}
      </ul>
    );
  }

  return (
    <ToggleButtonGroup
      type='radio'
      name='btn-rad'
      className={`mb-1 p-1 actions-bar ${style || ""}`}
      onChange={handleChange}
      value={quantity}
    >
      {numbers.map((number) => (
        <ToggleButton
          key={number}
          id={`tbg-btn-rad-${1 + number}`}
          className={`mt-0 min-w-max-content ${btnStyle || ""} ${sm || ""}`}
          variant='default'
          value={number}
        >
          {number} Ticket{number > 1 && "s"}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
