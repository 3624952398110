import ViewSwiper from "../ViewSwiper/ViewSwiper";
import Form from "react-bootstrap/Form";
import { SheetTop } from "../SheetTop";
import "./ticketDetails.scss";
import { formatCurrency } from "../../../../utilities/helpers";

export default function TicketDetails({
  toggleView,
  allowTicketCountChange = false,
  availableCount,
  selectionName,
  selectionDescription,
  selectionType,
  price,
  ticketCount,
  setTicketCount,
  offers,
  listing,
  eventTicketLimit,
}) {
  return (
    <div className='vjx-td'>
      <SheetTop canGoBack={true} goBack={toggleView} />
      <ViewSwiper viewImage={false} smallButtons={true} />
      <div className='vjx-tdd'>
        <svg
          width='18'
          height='19'
          viewBox='0 0 18 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3.66364 6.44068C3.66364 3.39682 6.02188 1 8.84545 1H9.15454C11.9781 1 14.3364 3.39682 14.3364 6.44068V13.2373C14.3364 13.4355 14.1923 13.5254 14.1 13.5254H3.9C3.80773 13.5254 3.66364 13.4355 3.66364 13.2373V6.44068ZM3.67854 16.8097C3.67858 16.8097 3.67823 16.8112 3.67735 16.814C3.67805 16.811 3.67849 16.8096 3.67854 16.8097ZM3.33013 17.0937C3.32999 17.0936 3.33264 17.0932 3.33853 17.0929C3.33321 17.0937 3.33027 17.0938 3.33013 17.0937ZM3.67672 17.13C3.74119 17.141 3.81432 17.1544 3.89945 17.1699C3.96151 17.1812 4.02995 17.1936 4.10607 17.2072C4.4351 17.2659 4.85509 17.3329 5.31299 17.3329H12.687C13.1449 17.3329 13.5649 17.2659 13.8939 17.2072C13.9701 17.1936 14.0385 17.1812 14.1006 17.1699C14.1857 17.1544 14.2588 17.141 14.3233 17.13C14.315 17.1834 14.302 17.2502 14.2825 17.3289C14.2421 17.4917 14.1836 17.6647 14.1149 17.8164C14.0803 17.893 14.0477 17.9537 14.0198 18H3.98019C3.95227 17.9537 3.91974 17.893 3.88508 17.8164C3.81645 17.6647 3.75789 17.4917 3.71747 17.3289C3.69795 17.2502 3.68501 17.1834 3.67672 17.13ZM4.06182 18.1118C4.06182 18.112 4.05981 18.1102 4.05579 18.106C4.0598 18.1095 4.06181 18.1116 4.06182 18.1118ZM13.9382 18.1118C13.9382 18.1116 13.9402 18.1095 13.9442 18.106C13.9402 18.1102 13.9382 18.112 13.9382 18.1118ZM14.6699 17.0937C14.6697 17.0938 14.6668 17.0937 14.6615 17.0929C14.6674 17.0932 14.67 17.0936 14.6699 17.0937ZM14.3227 16.814C14.3218 16.8112 14.3214 16.8097 14.3215 16.8097C14.3215 16.8096 14.3219 16.811 14.3227 16.814Z'
            stroke='#777E91'
            strokeWidth='2'
          />
        </svg>
        <span className='vjx-tdd-s'>{selectionName}</span>
      </div>
      {allowTicketCountChange && (
        <TicketCountChange
          selectionType={selectionType}
          availableCount={availableCount}
          ticketCount={ticketCount}
          setTicketCount={setTicketCount}
          price={price}
          offers={offers}
          listing={listing}
          eventTicketLimit={eventTicketLimit}
        />
      )}
      {selectionDescription && (
        <div className='vjx-td-desc'>
          <span className='vjx-td-desc-1'>Ticket description</span>
          <span className='vjx-td-desc-2'>{selectionDescription}</span>
        </div>
      )}
    </div>
  );
}

function TicketCountChange({
  selectionType,
  availableCount,
  price,
  ticketCount,
  setTicketCount,
  offers,
  listing,
  eventTicketLimit,
}) {
  const getTicketOptions = () => {
    const maxAllowed =
      eventTicketLimit !== null
        ? Math.min(eventTicketLimit, availableCount)
        : availableCount;

    if (listing) {
      return [Math.min(listing.quantity, maxAllowed)];
    }

    if (!offers) {
      return Array.from({ length: maxAllowed }, (_, index) => index + 1);
    }

    const offer = offers[0]; // Assuming we're dealing with the first offer
    const max = Math.min(maxAllowed, offer.maxQuantity || maxAllowed);

    if (offer.limit) {
      return [Math.min(offer.limit, max)];
    } else if (offer.maxQuantity) {
      return Array.from({ length: max }, (_, index) => index + 1);
    } else if (offer.minQuantity) {
      return Array.from(
        { length: Math.max(0, max - offer.minQuantity + 1) },
        (_, index) => index + offer.minQuantity
      ).filter((num) => num <= max);
    } else if (offer.multipleOf) {
      const options = [];
      for (let i = offer.multipleOf; i <= max; i += offer.multipleOf) {
        options.push(i);
      }
      return options;
    } else {
      return Array.from({ length: max }, (_, index) => index + 1);
    }
  };

  const options = getTicketOptions();

  return (
    <div className='vjx-tcc'>
      <div className='d-flex flex-column'>
        <span className='vjx-tcc-span'>{selectionType}</span>
        <p className='vjx-tcc-p'>
          {formatCurrency(price)} ea{" "}
          <span className='vjx-tcc-p-span'>incl. fees</span>
        </p>
      </div>
      <Form.Select
        value={ticketCount}
        onChange={(e) => setTicketCount(e.target.value)}
        aria-label='Default select example'
      >
        {options.map((number) => (
          <option key={number} value={number}>
            {number} {number === 1 ? "Ticket" : "Tickets"}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}
