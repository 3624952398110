import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  lazy,
} from "react";
import { CenterBottomModal } from "../../../components/CenterBottomModal";
import { useTickets } from "../TicketsProvider/TicketsProvider";
import { Spinner } from "../../../../LoadingContainer/Spinner";
import { Stack } from "react-bootstrap";

// Lazyload all these components since there is a high change most of them are never opened
const LazyTicketInfo = lazy(() =>
  import("../../../components/TicketInfo/TicketInfo")
);
const LazyViewSwiper = lazy(() =>
  import("../../../components/ViewSwiper/ViewSwiper")
);
const LazyQuantityFilter = lazy(() =>
  import("../../../components/QuantityFilter/QuantityFilter")
);
const LazyPriceFilterCard = lazy(() =>
  import("../../../components/PriceFilterCard/PriceFilterCard")
);
const LazyPriceSort = lazy(() =>
  import("../../../components/PriceSort/PriceSort")
);
const LazyEventInformation = lazy(() =>
  import("../../../components/EventInformation/EventInformation")
);

// Create the context
const ModalsContext = createContext(undefined);

export const useModals = () => {
  const context = useContext(ModalsContext);
  if (!context) {
    throw new Error("Context must be used within a ModalsProvider");
  }
  return context;
};

// Provide certain type of modals used in the page
export const ModalsProvider = ({ children }) => {
  const dialogRef = useRef(null);
  const { offers, event, updateSearchParam, eventTicketLimit } = useTickets();
  const [viewImage, setViewImage] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [CDBBlurred, setCDBBlurred] = useState(false);

  // Modal has different bg color + mobile padding
  // In these 2 cases
  useEffect(() => {
    const shouldBlur = [
      "Filter by section",
      "Unlock offer",
      "Event Information",
    ].includes(modalType);
    setCDBBlurred(shouldBlur);
  }, [modalType]);

  const openCBModal = (type) => {
    console.log("openCBMModal");
    setModalType(type);
    dialogRef.current.showModal();
  };

  const closeCBModal = () => {
    setModalType(null);
    dialogRef.current.close();
  };

  const modalComponents = {
    "Ticket information": () => <LazyTicketInfo />,
    "Your view": () => <LazyViewSwiper viewImage={viewImage} />,
    "Ticket Quantity": () => (
      <LazyQuantityFilter
        range={{ min: 1, max: eventTicketLimit ? eventTicketLimit : 50 }}
        style='actions-bar--vertical greyScale'
        btnStyle='btn-toggle--no-borders'
        handleChange={(val) => updateSearchParam("quantity", val)}
        dropdown={false}
      />
    ),
    "Price Range": () => <LazyPriceFilterCard closeModal={closeCBModal} />,
    "Sort by": () => (
      <LazyPriceSort
        style='actions-bar--vertical greyScale'
        btnStyle='btn-toggle--no-borders'
        handleChange={(val) => updateSearchParam("sort", val)}
        dropdown={false}
      />
    ),
    "Event Information": () => <LazyEventInformation event={event} />,
  };

  // Get the component based on the current modal type
  const ModalContent = modalType ? modalComponents[modalType] : null;

  return (
    <ModalsContext.Provider
      value={{
        openCBModal,
        setViewImage,
        offers,
        closeCBModal,
      }}
    >
      <CenterBottomModal
        dialogRef={dialogRef}
        handleClose={closeCBModal}
        title={modalType}
        blurred={CDBBlurred}
      >
        <Suspense
          fallback={
            <Stack className='align-items-center'>
              <Spinner variant='primary' size='lg' />
            </Stack>
          }
        >
          {ModalContent && <ModalContent />}
        </Suspense>
      </CenterBottomModal>
      {children}
    </ModalsContext.Provider>
  );
};
