import { useTickets } from "../../TicketsProvider/TicketsProvider";
import SheetBottom from "../../../../components/SheetBottom/SheetBottom";
import TicketDetails from "../../../../components/TicketDetails/TicketDetails";
import { useCheckout } from "../../CheckoutProvider";
import "./sideBottomModal.scss";
import { useEffect, useState } from "react";

export default function SideBottomModal({ dialogRef, handleClose }) {
  const { selectedFromQuickpicks, quantity, eventTicketLimit } = useTickets();
  const { checkout, checkingAvailability } = useCheckout();
  const [ticketCount, setTicketCount] = useState(1);

  useEffect(() => {
    setTicketCount(quantity);
  }, [quantity]);

  const handleClick = (event) => {
    if (event.target === dialogRef.current) {
      handleClose();
    }
  };

  console.log("selectedFromQuickpicks", selectedFromQuickpicks);

  // Check if selectedFromQuickpicks and its properties exist
  const hasValidSelection =
    selectedFromQuickpicks &&
    selectedFromQuickpicks.pick &&
    (selectedFromQuickpicks.pick.offers || selectedFromQuickpicks.pick.listing);

  // Prepare the props for TicketDetails only if we have valid data
  const ticketDetailsProps = hasValidSelection
    ? {
        toggleView: handleClose,
        allowTicketCountChange: true,
        availableCount: selectedFromQuickpicks.pick.availableCount,
        selectionName: selectedFromQuickpicks.pick.rowId
          ? `Sec ${selectedFromQuickpicks.pick.sectionId} • Row ${selectedFromQuickpicks.pick.rowId}`
          : "GENERAL ADMISSION",
        selectionDescription: selectedFromQuickpicks.pick.offers
          ? selectedFromQuickpicks.pick.offers[0].description
          : selectedFromQuickpicks.pick.listing.tickets[0].description,
        selectionType: selectedFromQuickpicks.pick.offers
          ? selectedFromQuickpicks.pick.offers[0].name
          : "Verified resale",
        price: selectedFromQuickpicks.pick.offers
          ? selectedFromQuickpicks.pick.price
          : selectedFromQuickpicks.pick.listing.askingPrice,
        ticketCount: ticketCount,
        setTicketCount: setTicketCount,
        offers: selectedFromQuickpicks.pick?.offers,
        listing: selectedFromQuickpicks.pick?.listing,
        eventTicketLimit
      }
    : null;

  const totalPrice = hasValidSelection
    ? (selectedFromQuickpicks.pick.offers
        ? selectedFromQuickpicks.pick.price
        : selectedFromQuickpicks.pick.listing.askingPrice) * ticketCount
    : 0;

  return (
    <dialog ref={dialogRef} onClick={handleClick} className='vjx-sbmodal'>
      <div className='sbmodal-inner'>
        <div className='sbmodal-content'>
          {hasValidSelection ? (
            <TicketDetails {...ticketDetailsProps} />
          ) : (
            <p>No ticket selected or invalid selection data.</p>
          )}
        </div>
        <SheetBottom
          totalTicketsPrice={totalPrice}
          selectedTicketCount={ticketCount}
          checkout={() => checkout(true)} // isQuickpick
          checkingAvailability={checkingAvailability}
        />
      </div>
    </dialog>
  );
}